import { FormGroup } from '@angular/forms';

export interface FileDTO {
  id?: number;
  file_name: string;
  original_name: string;
  type: string;
  size: number;
  is_deleted_file?: boolean;
  is_new_file?: boolean;
}

export interface FileDetailsDTO {
  id: number;
  file_name: string;
  original_name: string;
  type: string;
  size: number;
  token: string;
  url: string;
  downloadUrl: string;
  language: string;
}

export function convertFormToFileFormDTO(
  formGroup: FormGroup,
  fieldName: string = 'Documents'
): any {
  const document = formGroup.get(fieldName)?.value;
  if (document) {
    return {
      id: formGroup.get('id')?.value,
      file_name: document.file_name,
      original_name: document.original_name,
      type: document.type,
      size: document.size,
      language: 'en',
    };
  } else {
    return null;
  }
}

export function convertFormToFileFormDTOArray(
  formGroup: FormGroup,
  fieldName: string = 'Documents'
): any[] {
  const documents = formGroup.get(fieldName)?.value;
  if (Array.isArray(documents)) {
    return documents.map((document: any) => ({
      id: formGroup.get('id')?.value,
      file_name: document.file_name,
      original_name: document.original_name,
      type: document.type,
      size: document.size,
      language: 'en',
    }));
  } else {
    return [];
  }
}

export function convertResponseToFileDetailsDTO(response: any): FileDetailsDTO {
  return {
    id: response.id,
    file_name: response.name,
    original_name: response.original_name,
    type: response.type,
    token: response.token,
    size: response.size,
    url: response.size,
    downloadUrl: response.size,
    language: response.language,
  };
}
